import request from '../request'

export function helpPage(data) {
	return request({
		url: '/uc/ancillary/more/help/page',
		method: 'POST',
		data: data
	})
}


export function helpDetial(data) {
	return request({
		url: '/uc/ancillary/more/help/detail',
		method: 'POST',
		data: data
	})
}

export function helpPageTop(data) {
	return request({
		url: '/uc/ancillary/more/help/page/top',
		method: 'POST',
		data: data
	})
}