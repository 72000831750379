<template>
	<div class="admin-container">
		<Head :MyLocal="location" @newLocal="location=$event" />
		<div class="positon oent4-post positon-help"><span>
				<router-link to="/">{{$t('footmeun2')}}</router-link> - {{cateTitle}}
			</span></div>
		<div class="admin-main an-flex">
			<div class="admin-aside">
				<div class="acc_navbar">
					<div class="aside-bar">
						<div class="admin-tab-head">
							<div class="admin-tab-item" :class="{'is-active':current==item.id}" v-for="item in list"
								:key="item.id" @click="handleClik(item.id)">
								<span>{{item.title}}</span>
							</div>
							<div class="aside-more">
								<span @click="moreClick">{{$t('more')}} >> </span>
							</div>
						</div>
					</div>
					<i class="iconfont icon-menu1" @click="drawer = true" type="primary"></i>
					<el-drawer :visible.sync="drawer" :direction="direction" size="240px">
						<div class="acc_bar aside-bar acc_bar_sm">
							<div class="admin-tab-head">
								<div class="admin-tab-item" :class="{'is-active':current==item.id}" v-for="item in list"
									:key="item.id" @click="handleClik(item.id)">
									<span>{{item.title}}</span>
								</div>
								<div class="aside-more">
									<span @click="moreClick">{{$t('more')}} >> </span>
								</div>
							</div>
						</div>
					</el-drawer>
				</div>
			</div>
			<div class="admin-col whole_000 whole_fff">
				<div class="positon oent_box">
					<div class="oent-hd">
						<div class="oent-tit">{{detail.title}}</div>
						<div class="oent-time whole-hui">{{detail.createTime}}</div>
					</div>
					<div class="oent-bd" v-html="detail.content"></div>
					<div class="oent-ft">
						<div class="oent-ewm clearfix">
							<vue-qr :text="qrUrl" :margin="0" :size='300'></vue-qr>
							<p class="whole-hui">{{$t('etTxt2')}}</p>
						</div>
					</div>
					<div class="nav-bottom">
						<div class="nav-bottom__left">
							<el-button v-if="detail.back" @click="prev(detail.back.id)">
								<i class="el-icon-arrow-left"></i> {{$t('prev')}}
								<p>{{detail.back.title}}</p>
							</el-button>
						</div>
						<div class="nav-bottom__right">
							<el-button v-if="detail.next" @click="next(detail.next.id)">
								{{$t('next')}} <i class="el-icon-arrow-right"></i>
								<p>{{detail.next.title}}</p>
							</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		helpPageTop,
		helpDetial
	} from '@/api/api/foot'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	import vueQr from 'vue-qr'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot,
			vueQr
		},
		data() {
			return {
				list: [],
				current: 0,
				detail: {},
				location: 'en_US',
				cate: 0,
				cateTitle: '',
				drawer: false,
				direction: 'ltr',
				qrUrl: ""
			};
		},
		created() {
			this.cate = this.$route.query.cate
			this.cateTitle = this.$route.query.cateTitle
			this.current = this.$route.query.id
			this.location = localStorage.getItem('lang')
			this.qrUrl = window.location.href
		},
		mounted() {
			this.getList()
			this.getDetail()
		},
		methods: {
			getDetail() {
				let id = this.$route.query.id
				helpDetial({
					id: id
				}).then(res => {
					if (res.code == 0) {
						this.detail = res.data
						console.log(res)
					}
				})
			},
			getList() {
				helpPageTop({
					cate: this.cate,
					lang: this.location
				}).then(res => {
					console.log(res)
					if (res.code == 0) {
						this.list = res.data
					}
				})
			},
			handleCurrentChange(val) {
				this.currentPage = val

			},
			handleClik(id) {
				this.current = id
				this.$router.push({
					path: '/helpdetail',
					query: {
						cate: this.cate,
						id: id,
						cateTitle: this.cateTitle
					}
				})
				this.getDetail()
			},
			moreClick() {
				this.$router.push({
					path: '/helplist',
					query: {
						cate: this.cate,
						cateTitle: this.cateTitle
					}
				})
			}
		}
	}
</script>
